// extracted by mini-css-extract-plugin
export var container = "lQviKuNb";
export var content = "velDpK39";
export var header = "yGKkB6S5";
export var backButton = "OXNkH0QM";
export var body = "+D9A0gdG";
export var npm = "swkFP5VD";
export var socials = "hWhoueCe";
export var line = "rMoNgYrP";
export var form = "I6Ap-p2w";
export var signIn = "_6EBQIkWr";
export var passwordField = "Mm6VSQkc";
export var link = "jP9gezj0";
export var link__forgot = "_9tEI71+Y";
export var link__signup = "YNixOaX8";